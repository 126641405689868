<template>
  <div class="col-12 col-xl-8 mt-3">
    <validation-error-alert
      v-if="validationErrors"
      :errors="validationErrors"
    ></validation-error-alert>

    <b-form-group
      label-cols="12"
      label-cols-lg="2"
      label-size="sm"
      label="*角色名稱"
    >
      <b-form-input
        class="mb-2 mr-sm-2 mb-sm-0"
        type="text"
        :state="v$.role.name.$error ? false : null"
        v-model="role.name"
        :readonly="isReadOnly"
      ></b-form-input>
      <b-form-invalid-feedback :state="!v$.role.name.$error">
        此欄位為必填
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      label-cols="12"
      label-cols-lg="2"
      label-size="sm"
      label="*代碼"
    >
      <b-form-input
        class="mb-2 mr-sm-2 mb-sm-0"
        type="text"
        :state="v$.role.code.$error ? false : null"
        v-model="role.code"
        :readonly="isReadOnly"
      ></b-form-input>
      <b-form-invalid-feedback :state="!v$.role.code.$error">
        此欄位為必填
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      label-cols="12"
      label-cols-lg="2"
      label-size="sm"
      label="*Level"
    >
      <b-form-input
        class="mb-2 mr-sm-2 mb-sm-0"
        v-model="role.level"
        :readonly="isReadOnly"
      ></b-form-input>
      <b-form-invalid-feedback :state="!v$.role.level.$error">
        此欄位為必填且大於 0 的整數
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
      label-cols="12"
      label-cols-lg="2"
      label-size="sm"
      label="描述"
    >
      <b-form-input
        class="mb-2 mr-sm-2 mb-sm-0"
        type="text"
        v-model="role.description"
        :readonly="isReadOnly"
      ></b-form-input>
    </b-form-group>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import {required, integer, minValue} from "@vuelidate/validators";

export default {
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      role: {
        name: {required},
        code: {required},
        level: {required, integer, minValue: minValue(1)},
      },
    };
  },
  props: {
    validationErrors: null,
    isReadOnly: {
      type: Boolean,
      default: false
    },
    role: {
      type: Object,
      default() {
        return {
          name: null,
          code: null
        }
      }
    }
  },
  methods: {
		async validate () {
      console.log('handle validate')
      return await this.v$.$validate();
    }
  }
};
</script>
